// Authorization checks during navigation
export default defineNuxtRouteMiddleware((to: any, from: any) => {
    const userSession = reactive(useUserSession());
    const { unauthorizedRedirectTo } = useRuntimeConfig().public.auth;

    const { ensureDataIntegrity, endSession, isRouteAccessible } = useAuth();

    if (!ensureDataIntegrity())
        endSession();

    if (!isRouteAccessible(to)) {
        if (!userSession.isAuthenticated)
            return navigateTo(unauthorizedRedirectTo);
        else
            throw createError({ statusCode: 401, fatal: true });
    }
});