import type { FetchOptions } from 'ofetch';

export async function get<T = any>(url: string, options?: FetchOptions) {
    return fetch<T>(url, { ...options, method: 'GET' });
};

export async function post<T = any>(url: string, body?: any, options?: FetchOptions) {
    return fetch<T>(url, { ...options, body, method: 'POST' });
};

export async function put<T = any>(url: string, body?: any, options?: FetchOptions) {
    return fetch<T>(url, { ...options, body, method: 'PUT' });
};

export async function del<T = any>(url: string, options?: FetchOptions) {
    return fetch<T>(url, { ...options, method: 'DELETE' });
};

async function fetch<T = any>(url: string, options?: FetchOptions & { method: 'GET' | 'POST' | 'PUT' | 'DELETE' }) {
    return await useNuxtApp().$apiFetch<T>(url, options);
};