<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script lang="ts" setup>
    const { fetchCustomerId, customerId } = useUserSession();
    const { reloadCart } = useCart();

    watch(customerId, async () => {
        await reloadCart();
    });

    onMounted(async () => {
        //Since we use prerendering, we fetch customerId only on the client side
        await fetchCustomerId();
    });
</script>