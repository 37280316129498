import qs from "qs";
import type { FetchOptions } from 'ofetch';

import type { TokenModel } from '../types';

export const useAuth = () => {
    const userSession = reactive(useUserSession());

    const options = useRuntimeConfig().public.auth;

    async function login(username: string, password: string, rememberMe: boolean, redirect: string) {
        const data: TokenModel = {
            grant_type: 'password',
            username: username,
            password: password,
            scope: options.scope
        };

        const response = await fetch(options.tokenUri, { body: qs.stringify(data), headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, baseURL: '', method: 'POST' });

        userSession.setTokens(response.access_token, response.refresh_token);

        useCookie('userSessionDurationMarker', { maxAge: rememberMe ? (60 * 60 * 24 * options.rememberMeDuration) : undefined }).value = 'userSessionDurationMarker';

        await updateUserInfo();

        if (redirect)
            navigateTo(redirect);
    }

    async function updateUserInfo() {
        const response = await fetch(options.userInfoUri, { baseURL: '', method: 'GET' });

        userSession.setUserInfo(response);

        handleCookieComply(response?.enabled_analytics_cookie);
    }

    async function logout() {
        if (userSession.isAuthenticated)
            await fetch(options.logoutUri, { baseURL: '', method: 'GET' })

        endSession();

        navigateTo(options.logoutRedirectTo);
    }

    function endSession() {
        useCookie('userSessionDurationMarker').value = undefined;
        useCookie('cookie-comply').value = undefined;

        userSession.clear();
    }

    function ensureDataIntegrity() {
        if (!userSession.isAuthenticated && useCookie('userSessionDurationMarker').value)
            return false;

        if (userSession.isAuthenticated && !useCookie('userSessionDurationMarker').value)
            return false;

        return true;
    }

    function isRouteAccessible(route: any) {
        if (route.meta && route.meta.auth) {

            if (!userSession.isAuthenticated)
                return false;

            if (route.meta.auth.roles)
                return userSession.isInRole(route.meta.auth.roles);
        }

        return true;
    }

    function handleCookieComply(enabledAnalytics: Nullable<boolean>) {
        let cookieComply = useCookie('cookie-comply', { maxAge: 7948800 });

        cookieComply.value = undefined;

        if (enabledAnalytics !== null) {
            cookieComply.value = enabledAnalytics!.toString();

            if (!enabledAnalytics) {
                useCookie('utm-source-cookie').value = undefined;
                useCookie('utm-campaign-cookie').value = undefined;
                useCookie('utm-keyword-cookie').value = undefined;
            }
        }
    }

    async function fetch<T = any>(url: string, options?: FetchOptions & { method: 'GET' | 'POST' }) {
        return await useNuxtApp().$apiFetch<T>(url, options);
    }

    return {
        login,
        logout,
        ensureDataIntegrity,
        endSession,
        updateUserInfo,
        isRouteAccessible
    }
};