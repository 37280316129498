// Make cookie use state across app
// https://github.com/nuxt/nuxt/issues/13020

export default function useStatefulCookie<T = string>(name: string, maxAge?: number) {
    const cookieMaxAge = maxAge ? maxAge : 60 * 60 * 24 * 30; // 30 days
    
    const cookie = useCookie<T | undefined>(name, { maxAge: cookieMaxAge });
    const state = useState<T | undefined>(name, () => cookie.value);

    watch(state, () => { cookie.value = state.value; }, { deep: true });

    return state;
}