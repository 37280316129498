import type { CartItemViewModel } from '@/types/models/cart/cart-item-view-model';
import { cartService } from '@/services';

export const useCart = () => {
    const { customerId } = useUserSession();
    const cartItems = useState<CartItemViewModel[]>('cartItems', () => [])

    async function reloadCart() {
        cartItems.value = await cartService.getShoppingCart(customerId.value);
    }

    const cartItemCount = computed(() => cartItems.value?.reduce((sum: number, item: CartItemViewModel) => sum + item.quantity, 0));

    const cartSubTotal = computed(() => cartItems.value?.reduce((sum: number, item: CartItemViewModel) => sum + (item.price * item.quantity), 0));

    return { reloadCart, cartItemCount, cartSubTotal, cartItems }
}