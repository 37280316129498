export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    const cookie = useCookie('cookie-comply');

    if (cookie.value === undefined || cookie.value) {
        const gtmScript = document.createElement('script');

        gtmScript.async = true;
        gtmScript.defer = true;
        gtmScript.type = 'text/javascript';
        gtmScript.innerHTML = `(function (w, d, s, l, i) {w[l] = w[l] || []; w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'}); 
            var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);})
            (window, document, 'script', 'dataLayer', 'GTM-${config.public.gtm}');`,

        document.head.appendChild(gtmScript);
    }
});