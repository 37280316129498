import type { Pinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate';

import { getStorage } from './storages';

export default defineNuxtPlugin((nuxtApp) => {
    const pinia = (nuxtApp as any).$pinia as Pinia;
    const { storage } = useRuntimeConfig().public.piniaPersistedState;

    pinia.use(createPersistedState({
        storage: getStorage(storage)
    }));
})