import { get } from "./api-client";

export default {
    getCustomerIdByUser: async (userId?: number) => get<number>('/customer/getCustomerIdByUser', { params: { userId } }),

    isCustomerTaxExempt: async (customerId: number) => get<boolean>('/customer/IsCustomerTaxExempt', { params: { customerId } }),

    hasTaxCertificate: async (customerId: number) => get<boolean>('/customer/hasTaxCertificate', { params: { customerId } }),

    getPersonalDiscount: async (customerId: number) => get<number>('/customer/getPersonalDiscount', { params: { customerId } }),

    getCustomerCookieComplay: async (userId: Nullable<number>) => get<Nullable<boolean>>('/customer/getCustomerCookieComplay', { params: { userId } }),

    setCustomerCookieComplay: async (all: boolean, userId: Nullable<number>) => get('/customer/setCustomerCookieComplay', { params: { all, userId } })
};