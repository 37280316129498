import type { NuxtError } from '#app';

export default defineNuxtPlugin((nuxtApp) => {

    nuxtApp.hook('vue:error', (error, instance, info) => {
        if (!import.meta.client)
            return;

        const { errorToast } = useToast();

        //error was thrown with createError();
        if (isNuxtError(error)) {
            const nuxtError = error as NuxtError;
            
            if (nuxtError.fatal)
                return;

            if (Array.isArray(nuxtError.data) && nuxtError.data.length)
                errorToast('Oops! ' + nuxtError.data[0]);
            else
                errorToast('Oops! ' + nuxtError.statusMessage || nuxtError.message);
    
            clearError();

            return;
        }

        //regular runtime error
        if (error instanceof Error) {
            errorToast('Oops! ' + error.message);
            return;
        }
    });
});