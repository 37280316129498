import { useToast as useVueToastification} from 'vue-toastification';

export const useToast = () => {

    const toast = useVueToastification();

    const successToast = (message: string) => {
        return toast.success(removeHTMLTags(message), { icon: 'check' });
    };

    const errorToast = (message: string) => {
        return toast.error(removeHTMLTags(message), { icon: 'exclamation' });
    };

    return {
        successToast,
        errorToast
    };
};