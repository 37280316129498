import _ from 'lodash';

import { customerService } from '@/services';

export interface UserInfo {
    sub: number;
    email: string;
    name: string;
    roles: string[];
    has_local_account: Boolean;
    admin_username?: string;
    return_to?: string;
    enabled_analytics_cookie?: Boolean;
}

export const useUserSession = () => {
    const accessToken = useStatefulCookie('accessToken');
    const refreshToken = useStatefulCookie('refreshToken');
    const userInfo = useStatefulCookie<UserInfo>('userInfo');
    const customerId = useState<number>('customerId');

    const isAuthenticated = computed(() => accessToken.value != null);

    const userId = computed(() => {
        return isAuthenticated && userInfo.value != null ? userInfo.value.sub : undefined;
    });

    const hasLocalAccount = computed(() => isAuthenticated.value && userInfo.value != null && userInfo.value.has_local_account);

    const isLoggedInAsAnotherUser = computed(() => isAuthenticated.value && userInfo.value != null && userInfo.value.admin_username != null);

    async function fetchCustomerId() {
        customerId.value = await customerService.getCustomerIdByUser(userId.value);
    }

    function isInRole(roles: string | string[]) {
        if (!userInfo.value)
            return false;

        const rolesToMatch = typeof (roles) === 'string' ? [roles] : roles;

        return userInfo.value.roles.some((r) => rolesToMatch.includes(r));
    }

    function clear() {
        accessToken.value = undefined;
        refreshToken.value = undefined;
        userInfo.value = undefined;

        fetchCustomerId();
    }

    function setTokens(newAccessToken: string, newRefreshToken?: string) {
        accessToken.value = newAccessToken;
        refreshToken.value = newRefreshToken;
    }

    function setUserInfo(val?: UserInfo) {
        userInfo.value = !_.isEmpty(val) ? val : undefined;

        fetchCustomerId();
    }

    return { accessToken, refreshToken, isAuthenticated, userInfo, userId, customerId, hasLocalAccount, isLoggedInAsAnotherUser, isInRole, clear, setTokens, setUserInfo, fetchCustomerId };
}