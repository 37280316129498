import qs from "qs";
import type { FetchOptions, FetchResponse } from 'ofetch';
import type { $Fetch } from 'nitropack';

import type { RefreshTokenModel } from './types';

export default defineNuxtPlugin((nuxtApp) => {
    const options = useRuntimeConfig().public.auth;
    const { endSession } = useAuth();
    const userSession = reactive(useUserSession());

    const $apiFetch: $Fetch = $fetch.create({
        baseURL: '/api',

        onRequest(context) {
            setRequestHeaders(context.options);
        },

        async onResponse(context) {
            passCookiesToTheClient(context.response);

            if (isInvalidToken(context.response)) {
                const tokenRefreshed = await tryToRefreshToken();

                if (tokenRefreshed) {
                    //set headers and retry the original request
                    setRequestHeaders(context.options);

                    return await $fetch<any>(context.request, {
                        ...context.options as FetchOptions & { method: 'GET' | 'POST' | 'PUT' | 'DELETE' },
                        onResponse(ctx) {
                            //rewrite the response
                            Object.assign(context, ctx);

                            passCookiesToTheClient(context.response);
                        },
                    });
                }
                else 
                    endSession();
            }
        },

        async onResponseError({ response }) {
            if (!response) 
                return;

            switch (response.status) {
                case 400:
                    throw createError<string[]>({ statusCode: 400, statusMessage: response.statusText, data: collectCustomErrorsFromResponse(response) });

                case 401: case 404:
                    throw createError({ statusCode: response.status, fatal: true });

                default:
                    throw createError({ statusCode: response.status, statusMessage: response._data?.error ? response._data.error: response.statusText, fatal: true });
            }
        }
    });

    async function tryToRefreshToken() {
        const data: RefreshTokenModel = {
            grant_type: 'refresh_token',
            'refresh_token': userSession.refreshToken
        };

        try {
            const response = await $fetch<any>(options.tokenUri, { body: qs.stringify(data), headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, baseURL: '', method: 'POST' });

            userSession.setTokens(response.access_token, response.refresh_token);

            return true;
        }
        catch (error) {
            return false;
        }
    }

    function isInvalidToken(response: any) {
        if (!response)
            return false;

        if (!response.headers)
            return false;

        const status = response.status;
        const wwwAuthenticateHeader = response.headers.get('www-authenticate');

        return (status === 401 && wwwAuthenticateHeader && (wwwAuthenticateHeader.includes('invalid_token') || wwwAuthenticateHeader.includes('expired_token')));
    }
    
    function setRequestHeaders(options: FetchOptions) {
        //normalize headers
        options.headers = options.headers instanceof Headers ? options.headers : new Headers(options.headers);

        //forward client cookies to api, see https://nuxt.com/docs/getting-started/data-fetching#passing-headers-and-cookies
        if (nuxtApp.ssrContext)
            setHeader(options.headers, 'cookie', nuxtApp.ssrContext.event.node.req.headers.cookie);

        if (userSession.isAuthenticated)
            setHeader(options.headers, 'Authorization', 'Bearer ' + userSession.accessToken);
    }

    //see https://nuxt.com/docs/getting-started/data-fetching#passing-headers-and-cookies
    function passCookiesToTheClient(response: FetchResponse<any>) {
        if (!nuxtApp.ssrContext)
            return;

        const cookies = response.headers.getSetCookie();
            
        for (const cookie of cookies)
            nuxtApp.ssrContext.event.node.res.setHeader('set-cookie', cookie);

    }

    function setHeader(headers: Headers | undefined, name: string, value?: string) {
        if (!headers)
            return;

        if (!value)
            return;

        headers.set(name, value);
    }

    const collectCustomErrorsFromResponse = (response: FetchResponse<any>): string[] => {
        const data = response._data;

        if (!data)
            return [];

        const error = data.error_description ? data.error_description : data.error;

        if (!error)
            return [];

        return Array.isArray(error) ? error : [error];
    }

    return {
        provide: {
            apiFetch: $apiFetch
        }
    }
})

declare module "#app" {
    interface NuxtApp {
        $apiFetch: $Fetch;
    }
}