
import _ from 'lodash';

export function getStorage(storageType: string): Pick<Storage, 'getItem' | 'setItem'> {
    switch (storageType) {
        case 'cookies':
            return new SplitCookieStorage();

        case 'session':
            return new SessionStorage();

        default:
            return new LocalStorage();
    }
}

class LocalStorage {
    getItem(key: string): string | null {
        return !useNuxtApp().ssrContext ? localStorage.getItem(key) : null;
    }

    setItem(key: string, value: string) {
        if (!useNuxtApp().ssrContext)
            localStorage.setItem(key, value);
    }
}

class SessionStorage {
    getItem(key: string): string | null {
        return !useNuxtApp().ssrContext ? sessionStorage.getItem(key) : null;
    }

    setItem(key: string, value: string) {
        if (!useNuxtApp().ssrContext)
            sessionStorage.setItem(key, value);
    }
}

class SplitCookieStorage {
    maxNumberOfCookies = 10;
    storageMaxDurationInDays = 30;

    constructor() {
    }

    getItem(key: string): string | null {
        let cookie = '';
        let i = 0;
    
        while (true) {
            const c = useCookie(key + (i || '')).value;
    
            if (!c)
                break;
    
            cookie += c;
            i++;
        }
    
        return cookie;
    }

    setItem(key: string, value: string) {
        const val = _.isEmpty(JSON.parse(value)) ? '' : value;

        const cookieLength = Math.ceil(val.length / this.calcNumberOfCookies(val));
    
        for (let i = 0; i < this.maxNumberOfCookies; i++) {
            const start = i * cookieLength;
            const end = start + cookieLength;
    
            useCookie(key + (i || ''), {readonly: false, maxAge: (60 * 60 * 24 * this.storageMaxDurationInDays) }).value = (start < val.length) ? val.substring(start, end) : undefined;
        }
    }

    calcNumberOfCookies(value: string) {
        return Math.ceil(new TextEncoder().encode(value).length / 4096);
    }
}