const all=new Map()
export default {all,on(t,h){
const hs=all!.get(t)
if(hs)hs.push(h)
else all!.set(t,[h])
},off(t,h){
const hs=all!.get(t)
if(!hs)return
if(h)hs.splice(hs.indexOf(h)>>>0,1)
else all!.set(t,[])
},emit(t,e){
let hs=all!.get(t)
if(hs)hs.slice().map(h=>h(e!))
hs=all!.get('*')
if(hs)hs.slice().map(h=>h(t,e!))}}